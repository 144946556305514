import React from "react";
import {
  FileField,
  FileInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  NumberField,
  AutocompleteInput,
} from "react-admin";
import {
  SimpleForm,
  required,
  TextInput,
  DateInput,
  NumberInput,
} from "react-admin";
import { MarketArrayChipField, MarketReferenceArrayInput } from "../../market";
import { HorizontalGroup } from "../../reusable";
import {
  WebsiteReferenceArrayInput,
  WebsiteReferenceInput,
} from "../../website";
import { YoutubeReferenceArrayInput } from "../../youtube/inputs";
import { BrandReferenceInput } from "../brands";
import { LISTING_FEE_VERTICAL_CHOICES } from "../../constants";
import { PaymentStatus, PaymentTypes } from "./List";
import { WebsiteReferenceArrayField } from "../../website/fields";
import { YoutubeReferenceArrayField } from "../../youtube/fields";
import { makeStyles } from "@material-ui/core/styles";
import { useSalesUsers } from "../../reusable/hooks/useSalesUsers";
import { CURRENCY_CHOICES } from "./constants";
export const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid lightgray",
    padding: "8px",
  },
  label: {
    display: "block",
    fontFamily: "sans-serif",
  },
  box: {
    margin: "8px",
  },
  paper: {
    border: "1.5px solid blue",
    height: "3rem",
    paddingLeft: ".4rem",
    paddingRight: ".4rem",
    paddingTop: ".2rem",
    paddingBottom: ".2rem",
  },
}));

const check_date = (value, allValues) => {
  let paymentStatus = allValues?.payment_status;

  if (paymentStatus === "paid" && !value) {
    return "Please Enter payment date";
  }

  return undefined;
};

export const Form: React.FC<any> = (props) => {
  const classes = useStyles();
  const salesUsers = useSalesUsers();

  const checkSingleValue = (value, data) => {
    if (value && value.length > 1) {
      return "Only one value is allowed";
    }
    return undefined;
  };

  const checkSameCurrency = (value, data) => {
    const fees = data?.data?.fees;
    if (!fees) {
      // functions should return undefined when validation passes
      // https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#validation
      return;
    }
    const currency = fees.find((fee) => fee && fee?.currency !== value);
    if (currency) {
      return "Currency must be the same for all the breakdown fees!";
    }
    return;
  };

  return (
    <SimpleForm {...props} mutationMode="pessimistic" redirect={"list"}>
      <SelectArrayInput
        source="type"
        choices={[
          { id: "listing_fee", name: "Listing Fee" },
          { id: "flat_fee", name: "Flat Fee" },
          { id: "video_review", name: "Video Review" },
          { id: "youtube_top", name: "Youtube Top" },
        ]}
        id="listing_type"
        label="Select Listing Type"
        validate={[required()]}
      />
      <HorizontalGroup>
        <BrandReferenceInput
          source="brand"
          label="Brand"
          validate={[required()]}
        />
        <DateInput source="start_date" />
        <DateInput source="end_date" />
        <SelectInput
          source="fee_owner"
          choices={salesUsers.map((user) => ({
            id: user?.value,
            name: user?.label,
          }))}
        />
        <SelectInput
          source="vertical"
          choices={LISTING_FEE_VERTICAL_CHOICES}
          validate={[required()]}
        />
      </HorizontalGroup>
      <HorizontalGroup>
        <div className={classes.container}>
          <label className={classes.label}>Websites</label>
          <WebsiteReferenceArrayField
            className={classes.box}
            label="Websites"
            source={"website"}
          />
        </div>
        <div className={classes.container}>
          <label className={classes.label}>Youtubes</label>
          <YoutubeReferenceArrayField
            className={classes.box}
            label="Youtubes"
            source={"youtube"}
          />
        </div>
        <div className={classes.container}>
          <label className={classes.label}>Markets</label>
          <MarketArrayChipField
            className={classes.box}
            source="markets"
            label="Markets"
          />
        </div>
      </HorizontalGroup>
      <HorizontalGroup>
        <SelectInput
          source="payment_type"
          choices={Object.keys(PaymentTypes).map((key) => ({
            id: key,
            name: PaymentTypes[key as keyof typeof PaymentTypes],
          }))}
        />
        <SelectInput
          source="payment_status"
          choices={Object.keys(PaymentStatus).map((key) => ({
            id: key,
            name: PaymentStatus[key as keyof typeof PaymentStatus],
          }))}
        />
        <DateInput source="paid" validate={[check_date]} />
        <DateInput source="fee_date" />
      </HorizontalGroup>
      <HorizontalGroup>
        <div className={classes.container}>
          <label className={classes.label}>Amount</label>
          <NumberField source="amount" />
          <br />
          <NumberField source="fees_currency" />
        </div>
        <div
          className={classes.container}
          style={{ border: "1.5px solid blue" }}
        >
          <label className={classes.label}>Amount in EUR</label>
          <NumberField source="amount_in_eur" />
        </div>
        <TextInput source="received_amount" defaultValue={""} />
        {/* For now just keeping it as plain text as there can be various crypto currencies */}
        <TextInput source="currency" defaultValue={"EUR"} />
      </HorizontalGroup>

      <HorizontalGroup>
        <ArrayInput
          validate={[required()]}
          source="data.fees"
          label="Fees Breakdown"
        >
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                  <>
                    <SelectInput
                      {...rest}
                      source={getSource("type")} // Will translate to "data.fees[0].type"
                      choices={[
                        { id: "WEBSITE", name: "WEBSITE" },
                        { id: "YOUTUBE", name: "YOUTUBE" },
                      ]}
                      label="Product Type"
                    />
                    <HorizontalGroup>
                      {scopedFormData?.type === "WEBSITE" ? (
                        <WebsiteReferenceArrayInput
                          {...rest}
                          validate={[checkSingleValue]}
                          helperText={""}
                          prefetch={true}
                          source={getSource("websites")}
                          label="Website"
                        />
                      ) : scopedFormData?.type === "YOUTUBE" ? (
                        <YoutubeReferenceArrayInput
                          {...rest}
                          validate={[checkSingleValue]}
                          source={getSource("youtubes")}
                          label="Youtube"
                        />
                      ) : (
                        <></>
                      )}
                      <MarketReferenceArrayInput
                        {...rest}
                        source={getSource("markets")}
                        label="Markets"
                      />
                      <NumberInput
                        {...rest}
                        label="Amount"
                        onWheel={(e) => e.target.blur()}
                        source={getSource("amount")}
                        parse={(value) => value}
                        validate={[required()]}
                      />
                      <AutocompleteInput
                        {...rest}
                        label="Currency"
                        source={getSource("currency")}
                        choices={CURRENCY_CHOICES}
                        defaultValue={"EUR"}
                        validate={[checkSameCurrency]}
                      />
                      <div className={classes.paper}>
                        <label className={classes.label}>Amount in EUR</label>
                        <NumberField source={getSource("amount_in_eur")} />
                      </div>
                    </HorizontalGroup>
                    <SelectInput
                      {...rest}
                      label="Is this fee on account report?"
                      source={getSource("on_account_report")}
                      choices={[
                        { id: "YES", name: "✅ YES" },
                        { id: "NO", name: "❌ NO" },
                      ]}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </HorizontalGroup>

      <HorizontalGroup>
        <TextInput source="contact_person" />
        <TextInput multiline source="information" />
      </HorizontalGroup>
      <HorizontalGroup label="Invoice">
        <TextInput source="invoice_number" />
        <TextInput multiline source="invoice_details" />
      </HorizontalGroup>
      <FileField source="invoice_url" title="Invoice Document" />
      <FileInput source="invoice" label="Upload Invoice">
        <FileField source="invoice_url" title="Invoice Document" />
      </FileInput>

      <FileInput
        source="attachments"
        label="Upload Additional Documents"
        multiple
      >
        <FileField source="src" title="Upload Additional Documents" />
      </FileInput>

      <ArrayInput source="data.attachments">
        <SimpleFormIterator
          getItemLabel={(index) => `${index + 1}`}
          disableReordering
          disableAdd
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
              return (
                <>
                  <a href={scopedFormData?.url}>preview</a>
                  <TextInput
                    source={getSource("name")}
                    label="File Name"
                    disabled
                    fullWidth
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
