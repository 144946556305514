import React, { FC, useReducer, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useInput, InputProps } from "react-admin";
import {
    DateRangePicker,
    defaultStaticRanges as _defaultStaticRanges,
    createStaticRanges,
} from "react-date-range";
import format from "date-fns/format";
import { enGB } from "date-fns/locale";
import { getDayFromNow } from "../functions";
import { makeStyles } from "@material-ui/core/styles";
import { endOfDay } from "date-fns";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface DateRangeDialogProps extends Omit<InputProps, "source"> {
    label?: string;
    startDate?: number | Date;
    endDate?: number | Date;
    sourceBefore?: string;
    sourceAfter?: string;
    withTime?: boolean;
    resettable?: boolean;
    source?: string;
    dataTestid?: string;
    [key: string]: any;
}

interface DateRangeProps extends Omit<InputProps, "source"> {
    startDate?: number | Date | null;
    endDate?: number | Date | null;
    dispatchStartDate?: (action: { value: Date | null }) => void;
    dispatchEndDate?: (action: { value: Date | null }) => void;
    sourceBefore?: string;
    sourceAfter?: string;
    [key: string]: any;
}

const defaultStaticRanges = createStaticRanges([
    ..._defaultStaticRanges,
    {
        label: "All Time",
        range: () => ({
            startDate: new Date(0),
            endDate: endOfDay(new Date()),
        }),
    },
]);

const useStyles = makeStyles({
    container: {
        marginBottom: "8px",
        "&>button": {
            height: "3rem",
        },
    },
});

type ReducerAction = { value: Date | null };

const createReducer =
    (input: { input: { onChange: (value: string | null) => void } }, withTime: boolean) =>
    (state: Date | null, action: ReducerAction): Date | null => {
        input.input.onChange(
            action.value
                ? format(
                      action.value,
                      withTime ? "yyyy-MM-dd'T'HH:mm:ss.SSSxxx" : "yyyy-MM-dd"
                  )
                : null
        );
        return action.value;
    };

/**
 * This shows a date range
 */
export const DateRangeDialog: FC<DateRangeDialogProps> = ({
    label,
    startDate = getDayFromNow(-14),
    endDate = new Date(new Date().toISOString().slice(0, 10)),
    sourceBefore = "",
    sourceAfter = "",
    withTime = true,
    resettable,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const start = useInput({
        ...props,
        source: sourceAfter !== "" ? sourceAfter : `${props.source}__gte`,
    });
    const end = useInput({
        ...props,
        source: sourceBefore !== "" ? sourceBefore : `${props.source}__lte`,
    });

    const sd: Date = Number.isInteger(startDate)
        ? getDayFromNow(startDate as number)
        : (startDate as Date);
    const ed: Date = Number.isInteger(endDate)
        ? getDayFromNow(endDate as number)
        : (endDate as Date);

    const [startDate_, dispatchStartDate] = useReducer(
        createReducer(start, withTime),
        sd
    );
    const [endDate_, dispatchEndDate] = useReducer(
        createReducer(end, withTime),
        ed
    );

    const text =
        startDate_ && endDate_
            ? `${format(startDate_, "yyyy-MM-dd")} / ${format(
                  endDate_,
                  "yyyy-MM-dd"
              )}`
            : startDate_
            ? `from ${format(startDate_, "yyyy-MM-dd")}`
            : endDate_
            ? `to ${format(endDate_, "yyyy-MM-dd")}`
            : "Any date";

    return (
        <span className={classes.container}>
            {resettable ? (
                <Button
                    startIcon={<HighlightOffIcon />}
                    onClick={() => {
                        dispatchEndDate({ value: null });
                        dispatchStartDate({ value: null });
                    }}
                    style={{
                        padding: 0,
                        margin: 0,
                        width: "1rem",
                        minWidth: "2rem",
                        letterSpacing: 0,
                    }}
                />
            ) : null}
            <Button
                variant="outlined"
                color="primary"
                onClick={handleOpen}
                data-testid={props?.dataTestid}
            >
                {`${label || props.source}: ${text}`}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DateRange
                    sourceAfter={sourceAfter}
                    sourceBefore={sourceBefore}
                    startDate={startDate_}
                    endDate={endDate_}
                    dispatchStartDate={dispatchStartDate}
                    dispatchEndDate={dispatchEndDate}
                    {...props}
                />
            </Dialog>
        </span>
    );
};

/**
 * This shows a date range
 */
export const DateRange: FC<DateRangeProps> = ({
    startDate = getDayFromNow(-14),
    endDate = new Date(new Date().toISOString().slice(0, 10)),
    dispatchStartDate,
    dispatchEndDate,
    sourceBefore = "",
    sourceAfter = "",
    ...props
}) => {
    const selectionRange = {
        startDate: startDate as Date,
        endDate: endDate as Date,
        key: "range",
    };
    const [ranges, setRanges] = useState([selectionRange]);

    const onChange = ({
        range,
    }: {
        range: { startDate: Date; endDate: Date };
    }) => {
        if (dispatchStartDate) {
            dispatchStartDate({ value: range.startDate });
        }

        if (dispatchEndDate) {
            dispatchEndDate({ value: range.endDate });
        }

        setRanges([
            {
                startDate: range.startDate,
                endDate: range.endDate,
                key: "range",
            },
        ]);
    };

    return (
        <DateRangePicker
            ranges={ranges}
            onChange={onChange}
            staticRanges={defaultStaticRanges}
            locale={enGB}
            {...props}
        />
    );
};