import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import { TASK_STATUS, AI_PROOFREADING_API_URL } from "./constants";
import { post } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0.8rem",
    whiteSpace: "pre-wrap",
    marginBottom: theme.content.bottomBarHeight,
  },
  btn: {
    position: "fixed",
    width: "42px",
    height: "42px",
    bottom: "40%",
    right: "25rem",
    border: "transparent",
    borderTopLeftRadius: "25%",
    borderBottomLeftRadius: "25%",
    backgroundColor: "#2196f3",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1a78c2",
    },
    color: "white",
    textalign: "center",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: "1rem",
    alignItems: "flex-start",
  },
}));

const AiProductReading = ({ toggleDrawer, record }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [taskStatus, setTaskStatus] = useState(TASK_STATUS.NOT_CREATED);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [version, setVersion] = useState("");
  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(
      `${AI_PROOFREADING_API_URL}${record.id}/get_result/`,
    );
    if (response.status === 404) {
      console.log("Task not found");
      setTaskStatus(TASK_STATUS.NOT_CREATED);
      setLoading(false);
      setErrorMessage("Task not found");
      return;
    }
    if (response.status === 200) {
      const data = await response.json();
      setVersion(data?.data?.api_version);
      setData(data?.task_result);
      if (data?.task_status === "completed") {
        setTaskStatus(TASK_STATUS.COMPLETED);
      } else if (data?.task_status === "processing") {
        setTaskStatus(TASK_STATUS.PROCESSING);
      } else {
        setError(true);
        setTaskStatus(TASK_STATUS.FAILED);
        setErrorMessage(data?.task_error);
      }
      setLoading(false);
    }
  };

  const generateAiProofread = async () => {
    setLoading(true);
    try {
      const response = await post(AI_PROOFREADING_API_URL, {
        content_id: record.id,
      });
      if (response.status === 200 || response.status === 201) {
        setTaskStatus(TASK_STATUS.CREATED);
        setLoading(false);
      } else {
        setError(true);
        setErrorMessage(response?.data?.error);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [record]);

  return (
    <div className={classes.container}>
      <button className={classes.btn} onClick={toggleDrawer}>
        <ChevronRightIcon />
      </button>

      <Typography variant="h6">
        AI Proofreading
        <small>
          <i>Version: {version}</i>
        </small>
      </Typography>
      <Divider />
      {loading && <Typography variant="h6">Loading...</Typography>}
      <Typography variant="h6">
        Task Status: <b>{taskStatus}</b>
      </Typography>
      {error && (
        <>
          <Typography variant="h6" color="error">
            Error generating AI Proofread
          </Typography>
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        </>
      )}
      {(taskStatus === TASK_STATUS.NOT_CREATED ||
        taskStatus === TASK_STATUS.FAILED) && (
        <Button
          variant="contained"
          color="primary"
          onClick={generateAiProofread}
          disabled={loading}
        >
          Generate AI Proofread
        </Button>
      )}
      {taskStatus === TASK_STATUS.PROCESSING && (
        <Typography variant="h6">
          AI is generating proofread, please try after some time.
        </Typography>
      )}
      {(taskStatus === TASK_STATUS.CREATED ||
        taskStatus === TASK_STATUS.PROCESSING) && (
        <Button
          variant="contained"
          color="secondary"
          onClick={fetchData}
          disabled={loading}
        >
          Fetch Data
        </Button>
      )}

      {taskStatus === TASK_STATUS.COMPLETED && (
        <>
          <Typography variant="body1">
            <b>Clarity Score:</b> {data?.overall_assessment?.score}
          </Typography>
          <Divider />
          <Typography variant="body1">
            <b>Overall Content Assessment:</b>
            <small>
              <i>(Click on the title to view the review and revised text)</i>
            </small>
          </Typography>
          <Typography variant="body1">
            <details style={{ cursor: "pointer", lineHeight: "1.5rem" }} open>
              <summary>
                <b>Overall Content Guidelines:</b>
              </summary>
              <br />
              {data?.overall_assessment?.guidelines}
            </details>
          </Typography>
          <Divider />
          <Typography variant="body1">
            <b>Overall Review for each section:</b>
            <small>
              <i>(Click on the title to view the review and revised text)</i>
            </small>
          </Typography>
          <List>
            {data?.reviews?.map((review, index) => (
              <div
                key={index}
                style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
              >
                <details style={{ cursor: "pointer" }}>
                  <summary>{review.title}</summary>
                  <p>
                    <p>
                      <strong>Clarity Score:</strong> {review?.score}
                    </p>
                    <b>Suggestions:</b>{" "}
                    <div
                      style={{ lineHeight: "1.5rem" }}
                      dangerouslySetInnerHTML={{ __html: review.suggestions }}
                    />
                  </p>
                </details>
                <Divider />
              </div>
            ))}
          </List>
        </>
      )}
    </div>
  );
};

export default AiProductReading;
