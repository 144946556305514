import {
  Filter,
  FilterList,
  FilterListItem,
  BooleanInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import { OutreachSiteArrayInput } from "../outreachLinkbuildingSite";
import { WebsiteReferenceInput } from "../website";
import { outreachOrderStatus } from "../outreachOrder";
import { DateRangeDialog, HorizontalGroup } from "../reusable";
import { MarketReferenceArrayInput } from "../market";
import { UserReferenceInput } from "../user";
import { VerticalFilterList, VerticalReferenceArrayInput } from "../vertical";

import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import * as React from "react";
import { OUTREACH_PROJECT_CHOICES } from "../outreachMonthlyPlan/constants";

export const Filters = ({ picUsers, ...props }) => (
  <Filter {...props}>
    <DateRangeDialog
      dataTestid="filter-created"
      sourceBefore={"created_before"}
      sourceAfter={"created_after"}
      alwaysOn
      label={"Created"}
      resettable
    />
    <DateRangeDialog
      dataTestid="filter-plan-month"
      sourceBefore={"plan_month_before"}
      sourceAfter={"plan_month_after"}
      alwaysOn
      withTime={false}
      label={"Plan Month"}
      resettable
    />
    <DateRangeDialog
      dataTestid="filter-live-date"
      sourceBefore={"live_before"}
      sourceAfter={"live_after"}
      alwaysOn
      label={"Live"}
      resettable
    />
    <WebsiteReferenceInput allowEmpty={false} resettable alwaysOn />
    <TextInput source="link" label="Search Pages" alwaysOn resettable />
    <TextInput
      multiline
      label="Search Bulk Outreach Links"
      source={"bulk_outreach_links"}
      alwaysOn
    />
    <OutreachSiteArrayInput
      source="multiple_outreach_site"
      allowEmpty={false}
      alwaysOn
      resettable
    />
    <TextInput
      multiline
      label="Search Bulk Outreach Sites"
      source={"bulk_outreach_sites"}
      alwaysOn
    />
    <MarketReferenceArrayInput source="market" alwaysOn resettable />
    <VerticalReferenceArrayInput resettable alwaysOn />
    <UserReferenceInput
      allowEmpty={false}
      source="created_by"
      label={"Requested by"}
      alwaysOn
      resettable
    />
    <SelectInput
      choices={[
        { id: "1", name: "PBN" },
        { id: "2", name: "Guest Post" },
        { id: "3", name: "Free Links" },
        { id: "4", name: "Sales" },
      ]}
      label="Link Type"
      emptyText={"All"}
      source="outreach_type"
      resettable
      alwaysOn
    />
    <SelectInput
      choices={[
        { id: "1", name: "Spot Found" },
        { id: "2", name: "Spot not Found" },
      ]}
      label="Spot Status"
      emptyText={"All"}
      source="spot_exists"
      resettable
      alwaysOn
    />
    <SelectInput
      choices={picUsers.map((user) => ({
        id: user.value,
        name: user.label,
      }))}
      label="PIC"
      emptyText={"All"}
      source="order_pic"
      resettable
      alwaysOn
    />
    <SelectInput
      choices={OUTREACH_PROJECT_CHOICES}
      label="Project"
      emptyText={"All"}
      source="outreach_project"
      resettable
      alwaysOn
    />
    <BooleanInput
      source="indexed"
      label={"Show only not indexed"}
      alwaysOn
      resettable
    />
  </Filter>
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const FilterSidebar = (props) => (
  <Card>
    <CardContent>
      <PaymentFilterList />
      <StatusFilterList />
      <VerticalFilterList />
    </CardContent>
  </Card>
);

const StatusFilterList = () => {
  const items = outreachOrderStatus.choices.map((status) => (
    <FilterListItem
      label={status.name}
      value={{ status: status.id }}
      id={status.id}
    />
  ));
  return (
    <FilterList label={"Status"} icon={<InfoOutlinedIcon />}>
      {items}
    </FilterList>
  );
};

const PaymentFilterList = () => {
  return (
    <FilterList label={"Payment status"} icon={<MonetizationOnOutlinedIcon />}>
      <FilterListItem
        label={"Not requested"}
        value={{ not_paid: true, waiting_for_payment: false }}
      />
      <FilterListItem
        label={"Pending"}
        value={{ not_paid: true, waiting_for_payment: true }}
      />
      <FilterListItem label={"Paid"} value={{ not_paid: false }} />
    </FilterList>
  );
};
