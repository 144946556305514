import React from "react";

import {
  List as RAList,
  Datagrid,
  ListProps,
  NumberField,
  TextField,
  Filter,
  Pagination,
  downloadCSV,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import jsonExport from "jsonexport/dist";

import {
  DateRangeDialog,
  dayFromToday,
  ChipArrayField,
  getLocale,
  todayISOString,
} from "../../reusable";
import { MarketReferenceInput } from "../../market";
import { VerticalReferenceInput } from "../../vertical";
import { WebsiteReferenceField } from '../../website';
import { httpClient } from "../../utils";
import { API_URL } from "../../constants";
import Chip from "@material-ui/core/Chip";


const exporter = async (stats, fetchRelatedRecords) => {
  const data = stats.map((entry) => {
    const contentCost = entry.content_cost + entry.proofreading_cost;
    const linkbuildingCost = entry.linkbuilding_content_cost + entry.linkbuilding_proofreading_cost;
    return {
      domain: entry.domain,
      type: entry.type,
      vertical: entry.vertical_name,
      markets: entry.countries.join(", "),
      "Content Ordered": entry.ordered_content,
      "Content Published": entry.content_published,
      "Content Spent (EUR)": contentCost.toLocaleString(getLocale()) || "0",
      "Linkbuilding Content": entry.linkbuilding_ordered_content,
      "Linkbuilding Spent (EUR)":
        entry.linkbuilding_placement_cost.toLocaleString(getLocale()) || "0",
      "Linkbuilding Spent Content (EUR)":
        linkbuildingCost.toLocaleString(getLocale()) || "0",
      "Total Spent (EUR)": entry.total_cost.toLocaleString(getLocale()) || "0",
    }
  });
  jsonExport(
    data,
    {
      headers: [
        "domain",
        "type",
        "vertical",
        "markets",
        "Content Ordered",
        "Content Published",
        "Content Spent (EUR)",
        "Linkbuilding Content",
        "Linkbuilding Spent (EUR)",
        "Linkbuilding Spent Content (EUR)",
        "Total Spent (EUR)",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `website-stats.${todayISOString()}`)
        : console.error(err),
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "&>p": {
      paddingLeft: ".5rem",
      fontSize: ".7rem",
      "& b": {
        paddingRight: ".5rem",
      },
    },
  },
}));


const NewPagination = (props) => {
  const {
    resource, filterValues
  } = props;
  const classes = useStyles();
  const [stats, setStats] = React.useState({
    content: 0,
    linkbuilding: 0,
    total: 0,
  });
  React.useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams({
        totals:true,
        ...filterValues
      });
      const url = `${API_URL}/${resource}/?${params}`;
      const result = await httpClient(url);
      const content = 
        parseFloat(result.json.content_cost) +
        parseFloat(result.json.proofreading_cost);
      const linkbuilding = 
        parseFloat(result.json.linkbuilding_proofreading_cost) +
        parseFloat(result.json.linkbuilding_content_cost) +
        parseFloat(result.json.linkbuilding_placement_cost);
      const total = result.json.total_cost;
      setStats({
        content,
        linkbuilding,
        total
      });
    };
    fetchData();
  }, [filterValues, setStats, resource]);
  return (
    <span className={classes.footer}>
      <p>
        <span>
          Content spent: <b>{stats.content || "-"} EUR</b>
        </span>
        <span>
          Linkbuilding spent: <b>{stats.linkbuilding || "-"} EUR</b>
        </span>
        <span>
          Total spent:{" "}
          <Chip color="primary" label={`${stats.total || "-"} EUR`} />
        </span>
      </p>
      <Pagination {...props} />
    </span>
  );
};


const Filters = (props) => (
  <Filter {...props}>
    <SelectInput 
      source="aggregated_by" 
      choices={[
        { id: 'payment', name: 'Payment Date' },
        { id: 'approved', name: 'Approval Date' },
      ]} 
      alwaysOn
      allowEmpty={false}
    />
    <DateRangeDialog
      label={"Activity Date"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
      />
      <MarketReferenceInput allowEmpty={false} source={"markets"} alwaysOn />
      <VerticalReferenceInput allowEmpty={false} alwaysOn />
    </Filter>
)


export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList {...props}
      filters={<Filters />}
      pagination={<NewPagination />}
      filterDefaultValues={{
        after: dayFromToday(30),
        aggregated_by: 'payment'
      }}
      exporter={exporter}
    >
      <Datagrid >
        <WebsiteReferenceField source="product" />
        <TextField
          source={"vertical"}
        />
        <TextField
          source={"type"}
        />
        <ChipArrayField 
          source={"countries"} 
          label={"Markets"}
        />
        <NumberField
          source={"ordered_content"}
        />
        <NumberField
          source={"content_approved"}
        />
        <NumberField
          source={"content_published"}
        />
        <NumberField
          source={"linkbuilding_ordered_content"}
        />
        <NumberField
          source={"proofreading_cost"}
        />
        <NumberField
          source={"content_cost"}
        />
        <NumberField
          source={"linkbuilding_placement_cost"}
        />
        <NumberField
          source={"total_cost"}
          label={"Cost"}
        />
      </Datagrid>
    </RAList>
  );
};

export default List;
