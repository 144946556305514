import * as React from "react";

import { YtgField } from "../ytg/ytgField";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useGetYTG } from "../../yourtext_guru/hooks";
import SERP from "./utils/SERP";
import SearchIcon from "@material-ui/icons/Search";
import Notes from "./utils/notes";
import Instructions from "./utils/instructions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ChatIcon from "@material-ui/icons/Chat";
import Flag from "./utils/flag";
import { usePermissions } from "react-admin";
import { GROUPS } from "../../constants";
import Chat from "./utils/chat";
import { DisplayLiveUsers } from "./utils/LiveUsers";
import GroupIcon from "@material-ui/icons/Group";
import Badge from "@material-ui/core/Badge";
import AiProductReading from "./utils/AiProodreading";
import AssistantIcon from "@material-ui/icons/Assistant";

export const drawerWidth = "25rem";
export const closedDrawerWidth = "4rem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: theme.content.asideZIndex,
    marginBottom: theme.content.bottomBarHeight,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // probably not necessary now
    // whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& > .MuiCard-root": {
      overflowY: "auto",
    },
    // "& > *:last-child": {
    //   paddingBottom: theme.content.bottomBarHeight,
    // },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "auto",
    width: "auto",
    top: "5rem",
    overflowX: "hidden",
    background: "none",
    border: "none",
    "& > div": {
      "& > div:first-child, & > hr": {
        display: "none",
      },
    },
  },
  toolbar: {
    marginTop: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  divider: {
    margin: "24px",
  },
  button: {
    marginTop: "1rem",
  },
}));

export function WebpageAside({ record, className, basePath, activeUsers }) {
  const classes = useStyles();
  const permissions = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [ytg, setYtg] = React.useState(false);
  const [google, setGoogle] = React.useState(false);
  const [notes, setNotes] = React.useState(false);
  const [instruction, setInstruction] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [chat, setChat] = React.useState(false);
  const [liveUser, setLiveUser] = React.useState(false);
  const [aiProductReading, setAiProductReading] = React.useState(false);
  const activeUsersCount = activeUsers?.length;

  const { data, loading, error } = useGetYTG(record?.yourtext_guru_id);

  const editors = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.editors);

  const toggleDrawer = () => {
    setOpen(!open);
    setYtg(ytg ? false : ytg);
    setGoogle(google ? false : google);
    setNotes(notes ? false : notes);
    setInstruction(instruction ? false : instruction);
    setFlag(flag ? false : flag);
    setChat(chat ? false : chat);
    setLiveUser(liveUser ? false : liveUser);
    setAiProductReading(aiProductReading ? false : aiProductReading);
  };

  const toggleYtg = () => {
    setYtg(!ytg);
    setOpen(!open);
  };

  const toggleGoogle = () => {
    setGoogle(!google);
    setOpen(!open);
  };

  const toggleNotes = () => {
    setNotes(!notes);
    setOpen(!open);
  };

  const toggleInstruction = () => {
    setInstruction(!instruction);
    setOpen(!open);
  };

  const toggleFlag = () => {
    setFlag(!flag);
    setOpen(!open);
  };

  const toggleChat = () => {
    setChat(!flag);
    setOpen(!open);
  };

  const toggleLiveUser = () => {
    setLiveUser(!liveUser);
    setOpen(!open);
  };

  const toggleAiProductReading = () => {
    setAiProductReading(!aiProductReading);
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <Drawer
        anchor={"right"}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div>
          <div className={classes.toolbar}>
            <IconButton onClick={toggleDrawer}>
              {ytg |
              google |
              notes |
              instruction |
              flag |
              chat |
              liveUser |
              aiProductReading ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
        </div>
        <Divider />
        {open ? (
          ytg ? (
            <YtgField loading={loading} error={error} record={data} />
          ) : google ? (
            <SERP market={record.market} keywords={record.keywords} />
          ) : notes ? (
            <Notes record={record} />
          ) : instruction ? (
            <Instructions toggleDrawer={toggleDrawer} record={record} />
          ) : chat ? (
            <Chat record={record} basePath={basePath} editors={editors} />
          ) : liveUser ? (
            <DisplayLiveUsers activeUsers={activeUsers} />
          ) : flag && editors ? (
            <Flag record={record} basePath={basePath} />
          ) : aiProductReading && editors ? (
            <AiProductReading toggleDrawer={toggleDrawer} record={record} />
          ) : null
        ) : record?.yourtext_guru_id ? (
          <Button
            className={classes.button}
            variant="contained"
            onClick={toggleYtg}
            startIcon={<VisibilityIcon />}
          />
        ) : null}

        {!google && !open && (
          <Tooltip title="Google SERP" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleGoogle}
              startIcon={<SearchIcon />}
            />
          </Tooltip>
        )}

        {!notes && !open && (
          <Tooltip title="Writer Notes" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleNotes}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        )}

        {!instruction && !open && (
          <Tooltip title="Instructions" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleInstruction}
            >
              <AssignmentIcon />
            </Button>
          </Tooltip>
        )}

        {!flag && !open && editors && (
          <Tooltip title="Content Flags" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleFlag}
            >
              <LocalOfferIcon />
            </Button>
          </Tooltip>
        )}

        {!chat && !open && (
          <Tooltip title="Chat" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleChat}
            >
              <ChatIcon />
            </Button>
          </Tooltip>
        )}

        {!liveUser && !open && (
          <Tooltip title="Chat" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleLiveUser}
            >
              <Badge badgeContent={activeUsersCount} color="error">
                <GroupIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}

        {!aiProductReading && !open && (
          <Tooltip title="Ai Product Reading" placement="left">
            <Button
              className={classes.button}
              variant="contained"
              onClick={toggleAiProductReading}
            >
              <AssistantIcon />
            </Button>
          </Tooltip>
        )}
      </Drawer>
    </div>
  );
}
